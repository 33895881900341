.container-fluid {
  .spin-row {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }

  .ant-row {
    .ant-col {
      .ant-card {
        height: 200px !important;
        border-radius: 15px !important;
        margin-bottom: 50px;

        .ant-card-body {
          padding: 0;
          border-radius: 15px !important;
          outline: none;
          overflow: hidden;

          .ant-image {
            height: 200px !important;
          }

          .icon-center {
            .icon-center-delete {
              display: none;
              position: absolute;
              top: 10px;
              right: 10px;
            }

            .icon-center-checkbox {
              position: absolute;
              top: 10px;
              left: 10px;
              border-radius: 50%;
              width: 20px;
              background: var(--subscribe);
              border: none;

              .ant-checkbox-input {
                width: 50%;
              }
            }
          }
        }

        &:hover {
          .icon-center {
            .icon-center-delete {
              display: block;
            }
          }
        }
      }

      .images {
        width: 260px !important;
        height: 200px;
      }

      .card-active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      }
    }
  }
}
