.statistics-card {
  overflow: hidden;
  .card-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 200px;
  }
  .space {
    flex-grow: 1;
  }
  .highlighter {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);

    &.green {
      animation: shinerGreen 1.2s infinite;
    }
    &.red {
      animation: shinerRed 1.2s infinite;
    }
    &.grey {
      animation: shinerGrey 1.2s infinite;
    }
    &.purple {
      animation: shinerPurple 1.2s infinite;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    text-align: center;
    .number {
      position: absolute;
      top: 30%;
      font-weight: 700;
      word-break: break-all;
      word-wrap: break-word;
      text-align: center;
    }
  }

  .title {
    text-align: center;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avatar {
  width: 50%;
  .avatar-text {
    width: calc(100% - 56px);
    .title {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }
}

@keyframes shinerGreen {
  0% {
    box-shadow: 0px 0px 45px 45px rgba(39, 227, 58, 0.1);
  }
  50% {
    box-shadow: 0px 0px 50px 50px rgba(39, 227, 58, 0.3);
  }
  100% {
    box-shadow: 0px 0px 45px 45px rgba(39, 227, 58, 0.1);
  }
}
@keyframes shinerRed {
  0% {
    box-shadow: 0px 0px 45px 45px rgba(255, 140, 140, 0.1);
  }
  50% {
    box-shadow: 0px 0px 50px 50px rgba(255, 140, 140, 0.3);
  }
  100% {
    box-shadow: 0px 0px 45px 45px rgba(255, 140, 140, 0.1);
  }
}
@keyframes shinerGrey {
  0% {
    box-shadow: 0px 0px 45px 45px rgba(181, 174, 174, 0.1);
  }
  50% {
    box-shadow: 0px 0px 50px 50px rgba(181, 174, 174, 0.3);
  }
  100% {
    box-shadow: 0px 0px 45px 45px rgba(181, 174, 174, 0.1);
  }
}
@keyframes shinerPurple {
  0% {
    box-shadow: 0px 0px 45px 45px rgba(135, 232, 222, 0.1);
  }
  50% {
    box-shadow: 0px 0px 50px 50px rgba(135, 232, 222, 0.3);
  }
  100% {
    box-shadow: 0px 0px 45px 45px rgba(135, 232, 222, 0.1);
  }
}
