.order-tabs {
  .tab-card {
    border-radius: 0 0.625rem 0.625rem 0.625rem;
  }
  .tabs-container {
    position: relative;
    width: 100%;
    padding-right: 50px;
    .tab-add-button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .tabs {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    bottom: -1px;
    z-index: 2;
    overflow-x: auto;
    .tab {
      flex-shrink: 0;
      position: relative;
      padding: 16px;
      padding-bottom: 10px;
      background-color: transparent;
      opacity: 0.5;
      border: 1px solid transparent;
      border-bottom: 0;
      border-radius: 0.625rem 0.625rem 0 0;
      cursor: pointer;
      &.active {
        background-color: #fff;
        opacity: 1;
        border-color: var(--border-color);
      }
      .close-button {
        display: block;
        font-size: 10px;
        color: var(--dark);
        font-weight: 500;
        padding: 3px;
        margin: 0;
        border-radius: 50%;
        &:hover {
          background-color: var(--sidebar-active);
        }
      }
    }
  }
}
