.chat {
  background: white;
  border-radius: 50%;
  padding: 5px;
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 7px rgba(61, 61, 61, 0.2);
  z-index: 99;
  .chat-icon {
    font-size: 25px;
  }
}
[data-theme='dark'] .chat {
  background: #4d5b75;
}
